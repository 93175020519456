/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // utility function to detect mobile
  function isMobileWidth() {
    return $('.menuToggle').is(':visible');
  }


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // animations
        AOS.init();

        // lazy loading
        /*
        var observer = lozad(); // lazy loads elements with default selector as '.lozad'
        observer.observe();
        */

        // language switcher js
        if(window.location.href.indexOf("/es") > -1) {
            $('.languageToggle').text("EN");
        } else {
            $('.languageToggle').text("ES");
        }

        $('.languageToggle').on("click", function () {
            var urlStr = window.location.href;

            var esCheck = urlStr.lastIndexOf('/es');

            if (esCheck > -1) {
                window.location.href = urlStr.substr(0, esCheck+1)
            } else {
                if(urlStr.substr(urlStr.length-1) == "/")
                    window.location.href = urlStr + "es"
                else
                    window.location.href = urlStr + "/es"
            }
        });


        $(window).on('load', function() {
          if (isMobileWidth()) {
            // get top level menu items
            var internet_landing = $('.menu-left > .menuWrap > .nav > li:first-child');
            var tv_landing = $('.menu-left > .menuWrap > .nav > li:last-child');

            // get top level menu links
            var internet_landing_link = internet_landing.children('a');
            var tv_landing_link = tv_landing.children('a')

            // get text from top links to create new divs for buttons
            var internet_landing_text = internet_landing_link.text();
            var tv_landing_text = tv_landing_link.text();
            var internet_button = $('<div class="sub-menu-button">' + internet_landing_text + '</div>');
            var tv_button = $('<div class="sub-menu-button">' + tv_landing_text + '</div>');

            // add new buttons to top of menu
            internet_landing.prepend(internet_button);
            tv_landing.prepend(tv_button);

            // get submenus
            var internet_submenu = internet_landing.children('ul.sub-menu');
            var tv_submenu = tv_landing.children('ul.sub-menu');

            // add cloned top links to top of submenus
            internet_submenu.prepend(internet_landing_link);
            tv_submenu.prepend(tv_landing_link);
            internet_landing_link.wrap('<li class="menu-item"></li>');
            tv_landing_link.wrap('<li class="menu-item"></li>');

            // keep mobile menu state when link is clicked
            $('.menu-left > .menuWrap > .nav > li a').on('click', function (e) {
              e.stopPropagation()
            });
          }
        });


        // Menu show/hide
        $('.menuToggle').on('click', function () {
          var menu = $('.menu-section');
          if (menu.hasClass('open')) {
            menu.slideUp();
            menu.removeClass('open');
          }
          else {
            menu.slideDown();
            menu.addClass('open');
          }
        });

        // Sub-Menus show/hide
        $('.menu-left > .menuWrap > .nav > li').on('click', function () {
          // get the sub menu and domm element
          var sub_menu = $(this).children('.sub-menu');
          var sub_menu_el = sub_menu.get(0);

          // first close any other sub menus
          $('.sub-menu').each( function() {
            var this_sub_menu = $(this);
            if (this_sub_menu.get(0) !== sub_menu_el) {
              this_sub_menu.removeClass('open').slideUp();
            }
          });

          // open the current menu
          if (sub_menu.hasClass('open')) {
            sub_menu.slideUp().removeClass('open');
          }
          else {
            sub_menu.slideDown().addClass('open');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //Articles Slider
        $('.articles-section__items').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          mobileFirst: true,
          nextArrow: '<div class="slick-next"><i class="fas fa-chevron-right"></i></div>',
          prevArrow: '<div class="slick-prev"><i class="fas fa-chevron-left"></i></div>',
          responsive: [{
            breakpoint: 1000,
            settings: {

            }
          }, {
            breakpoint: 750,
            settings: {

            }
          }, {
            breakpoint: 300,
            settings: {

            }
          }]
        });

        //SVG
        //unfurl into inline from image
        $('img.svg').each(function () {
          var $img = $(this);
          var imgID = $img.attr('id');
          var imgClass = $img.attr('class');
          var imgURL = $img.attr('src');

          $.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
              $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
              $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
              $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

          }, 'xml');

        });



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS



      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
